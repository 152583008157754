import { mapState, mapActions } from 'vuex'
import { formatDate, getStatusLabelFile } from '@/helpers'

export default {
  name: 'TFilesTableWaitingReturn',

  components: { TTableFiles: () => import('./table-files.vue') },

  data () {
    return {
      activeStatus: true,
      fileStatus: ['WaitingReturn'],
      thead: [
        { label: 'Data de Geração', name: 'generatedDate' },
        { label: 'Forma de Pagamento', name: 'paymentMethod' },
        { label: 'Registros:', name: 'registers' },
        { label: 'Pendentes', name: 'pending' },
        { label: 'Total', name: 'total' },
      ],
      totalPages: "0",
      currentPage: "0",
      pagesSelected: "0",
      total: 0,
      limit: 5,
      eventList: [],
      timerID: ''
    }
  },

  props: {
    files: {
      type: Array,
      default: () => []
    },

    loading: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState({
      fileListWaitingReturn: ({ files }) => files.fileListWaitingReturn,
      metaDataListWaitingReturn: ({ files }) => files.metaDataListWaitingReturn,
      currentYear: ({ files }) => files.currentYear,
      currentMonty: ({ files }) => files.currentMonty,
      isGeneratingFile: ({ files }) => files.isGeneratingFile,
    }),

    filesList () {
      this.bindPlugin()
      return this.fileListWaitingReturn
    }
  },

  watch: {
    fileListWaitingReturn (newValue) {
      this.getStatusPending()
    }
  },

  methods: {
    ...mapActions('files', ['getFilesWaitingReturn', 'toggleGeneratingFile']),
    ...mapActions('notifications', [ 'toggleNotification' ]),
    getHeadClass (headName, tableName) {
      if (headName === 'billings' || (headName === 'registers' && tableName !== 'importedReturns')) {
        return 'black-300'
      }

      if (headName === 'paymentMethod') {
        return 'column-100'
      }
    },

    solveColumnItem (item, key) {
      if (key === 'id' || key === 'bankCode' || key === 'outputFileUrl') {
        return 'hidden'
      }

      if (!isNaN(item) && key !== 'id') {
        return 'center aligned'
      }
    },

    bindPlugin () {
      Vue.nextTick().then(() => {
        $(`.extra-options .tui.ui.dropdown`).dropdown()
      })
    },

    prevPage () {
      if (this.currentPage === 1) return

      this.getFilesWaitingReturn(
        {
          year: this.currentYear,
          month: this.currentMonty,
          page: Number(this.currentPage) - 1,
          limit: this.limit
        }
      )
    },

    nextPage () {
      if (this.currentPage === this.totalPages) return

      this.getFilesWaitingReturn(
        {
          year: this.currentYear,
          month: this.currentMonty,
          page: Number(this.currentPage) + 1,
          limit: this.limit
        }
      )
    },

    goToPage (page) {
      if (Number(page) < 0 || Number(page) > Number(this.totalPages)) return
      this.currentPage = page
      this.getFilesWaitingReturn(
        {
          year: this.currentYear,
          month: this.currentMonty,
          page: Number(page),
          limit: this.limit
        }
      )
    },

    updateMetaDataTable () {
      if (this.metaDataListWaitingReturn) {
        this.totalPages = `${this.metaDataListWaitingReturn.totalPages}`
        this.currentPage = `${this.metaDataListWaitingReturn.currentPage}`
        this.pagesSelected = `${this.metaDataListWaitingReturn.limitValue}`
        this.total = this.metaDataListWaitingReturn.totalCount
      }
    },

    formatedData (data) {
      return {
        generatedDate: formatDate(data.createdAt),
        paymentMethod: `${data.paymentMethodLabel}: ${data.bankName} ${getStatusLabelFile(data.status, data.downloadedAt)}`,
        registers: '',
        pending: data.pending,
        total: data.total,
        bankCode: data.bankCode,
        id: data.id
      }
    },

    getDownloadFile (data) {
      const { id } = data
      const url = `/v1/admin/output_process_requests/${id}/download`

      this.$http.get(url).then(res => {
        window.location.href = res.data.output_file_url
        this.getFilesWaitingReturn({ year: this.currentYear, month: this.currentMonty })
      }).catch(e => {
        Vue.$log.error('error', e)
        this.toggleNotification({ active: true, type: 'error' })
      })
    },

    bankName: data => data.bankName,

    generationDate: data => formatDate(data.createdAt),

    showDownload: data => {
      if (data.status !== 'processed') {
        return false
      } else {
        return true
      }
    },

    getStatusPending () {
      const statusPending = this.fileListWaitingReturn.filter(item => item.status === 'pending')
      if (statusPending.length > 0) {
        this.timerID = window.setTimeout(() => {
          this.getFilesWaitingReturn({ year: this.currentYear, month: this.currentMonty })
        }, 15000)
      }
    }
  },

  mounted () {
    this.toggleGeneratingFile()
    this.updateMetaDataTable()
  },

  updated () {
    this.toggleGeneratingFile()
    this.updateMetaDataTable()
  },

  beforeDestroy () {
    window.clearTimeout(this.timerID);
  }
}
